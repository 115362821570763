import React, { useState } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { RegisterContainer, RegisterButton, ColumnRow1 } from "./styles";
import { PageTitle } from "../Creditos/styles";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import api from "../../../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import axios library
import { Formik, Form, Field, ErrorMessage } from "formik";
import Input from "../../../Components/Input";
import * as Yup from "yup";
import IdentityPhotoUploader from "./dropzone";
import PageContainer from "../../../Components/PageContainer";
import CreatableSelect from "react-select/creatable";
import { Label } from "../../../Components/style";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale/pt-BR";
registerLocale("pt-BR", ptBR);

export default function CadastrarMedico() {
  const requestFormData = global.FormData;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const history = useHistory();
  const [startDate, setStartDate] = useState("");

  // async function sendToStorage(state) {
  //   try {
  //     const uriArray = identityPhoto.name.split(".");
  //     const tipoImagem = uriArray[uriArray.length - 1];

  //     const firstResponse = await api.get(`/put_url/${tipoImagem}`);

  //     const fileName = firstResponse.data.file_key;
  //     const mediaUrl = firstResponse.data.put_url;

  //     const secondResponse = await axios.put(mediaUrl, state, {
  //       headers: {
  //         "Content-Type": tipoImagem,
  //         /* "Content-Disposition": "attachment",
  //         "x-amz-acl": "public-read", */
  //       },
  //     });

  //     return fileName;
  //   } catch (err) {
  //     console.log("erro na sec:", err);
  //   }
  // }

  const sendData = async (values) => {
    setLoading(true);
    console.log(values, "console values");
    try {
		  console.log("começamos o try")
      console.log(identityPhoto);
      // const fileName = await sendToStorage(identityPhoto);
      // const fileProfile = await sendToStorage(profilePhoto);

      //formatando a imagem para o back
      const formData = new requestFormData();
      formData.append("images", identityPhoto);
      formData.append("images", profilePhoto);
      console.log(values["Especialidades"]);
      const especialidadesArray = [];
      await values["Especialidades"].map((objeto) => {
        especialidadesArray.push(objeto.value);
      });
	    console.log("cheguei aqui");
      const data = {
        NO_primeiro_nome: values["Nome"],
        NO_ultimo_nome: values["Sobrenome"],
        DS_email: values["Email"],
        DS_hash_senha: values["Senha"],
        // img_url: fileName,
        DS_complemento: values["Complemento"],
        NU_endereco: values["Número"],
        NO_rua: values["Endereço"],
        NO_bairro: values["Bairro"],
        NO_cidade: values["Cidade"],
        NO_estado: values["Estado"],
        DS_genero: values["Sexo:"],
        DT_nascimento: values["Data de nascimento"].toISOString(),
        NU_cnpj: values["CNPJ"].replace(/\D/g, ""),
			  NU_cpf: values["CPF"].replace(/\D/g, ""),
        NU_telefone: values["Contato"].replace(/\D/g, ""),
        CD_numero_registro_profissional: values["Número de registro profissional"],
        // dados_bancarios: {
        //   cep: values["CEP"].replace(/\D/g, ""),
        TP_chave_pix: values["Tipo chave"],
        CD_chave_pix: values["Chave PIX"],
        //   address: values["Endereço"],
        //   neighbourhood: values["Bairro"],
        //   city: values["Cidade"],
        //   state: values["Estado"],
        // },
        // especialidades: especialidadesArray,
      };
	    // console.log("cheguei aqui2");
      // console.log(data["medico"]);
      // if (values["CPF"] !== "" && values["CNPJ"] === "") {
      //   data["medico"] = {
      //     endereco: values["Endereço"],
      //     numero_de_registro_profissional:
      //       values["Número de registro profissional"],
      //     telefone: values["Contato"].replace(/\D/g, ""),
      //     creditos_consulta: 1,
      //     profile_image: fileProfile,
      //     NU_cpf: values["CPF"].replace(/\D/g, ""),
      //     especialidades: values["Especialidades"].map((objeto) => {
      //       return { nome: objeto.value };
      //     }),
      //   };
      //     console.log("FOI");
      //   } else if (values["CPF"] === "" && values["CNPJ"] !== "") {
      //     data["medico"] = {
      //       endereco: values["Endereço"],
      //       numero_de_registro_profissional:
      //         values["Número de registro profissional"],
      //       telefone: values["Contato"].replace(/\D/g, ""),
      //       creditos_consulta: 1,
      //       profile_image: fileProfile,
      //       NU_cnpj: values["CNPJ"].replace(/\D/g, ""),
      //       especialidades: values["Especialidades"].map((objeto) => {
      //         return { nome: objeto.value };
      //       }),
      //     };
      //   } else {
      // data["medico"] = {
      // 	endereco: values["Endereço"],
      // 	numero_de_registro_profissional:
      // 	  values["Número de registro profissional"],
      // 	NU_telefone: values["Contato"].replace(/\D/g, ""),
      // 	creditos_consulta: 1,
      // 	profile_image: fileProfile,
      // 	NU_cnpj: values["CNPJ"].replace(/\D/g, ""),
      // 	NU_cpf: values["CPF"].replace(/\D/g, ""),
      // 	especialidades: values["Especialidades"].map((objeto) => {
      // 	  return { nome: objeto.value };
      // 	}),
      //   };
      // }
      //  Corrigindo data de nascimento para enviar ao back
      // console.log("corrigindo a data de nascimento para enviar");
      // const diaNascimento = 
      // values["Data de nascimento"].getDate().length === 1
      // ? '0' + values["Data de nascimento"].getDate().toString()
      // : values["Data de nascimento"].getDate().toString();
      // const mesNascimento = 
      // (values["Data de nascimento"].getMonth()+1).length === 1 
      // ? '0' + (values["Data de nascimento"].getMonth()+1).toString() 
      // : (values["Data de nascimento"].getMonth()+1).toString();
      // const anoNascimento = values["Data de nascimento"].getFullYear().toString();

      // data["DT_nascimento"] = anoNascimento+'-'+mesNascimento+'-'+diaNascimento;

      // adicionando todos os valores do formik no formData
      Object.keys(data).forEach((key) => {
        const value = data[key];
        // adicionando no form data
        formData.append(key, String(value));
      });

      formData.append('especialidades', JSON.stringify(especialidadesArray));

      console.log("pqp começou a request");
      console.log(formData);

      const response = await api.post("/usuario/registrar/medico", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      console.log(response.data);
      const showToastMessage = () => {
        toast.success(`Cadastro realizado com sucesso!`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
      history.push("/cadastrar");
    } catch (error) {
        console.log("erro ao cadastrar", error);
        console.log(error?.response.data.error);

        if (
          error?.response?.data?.error ===
          "Usuário já existe com as informações informadas"
        ) {
          toast.error(`Usuário já existe com as informações informadas`, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error(`Erro ao cadastrar!`, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
    }
    setLoading(false);
  };

  const specialtiesList = [
    "Acupuntura",
    "Agente comunitário de saúde",
    "Alergia e imunologia",
    "Anatomopatologista",
    "Anestesiologista",
    "Angiologia",
    "Assistente social",
    "Atendente de consultório dentário",
    "Auxiliar de enfermagem",
    "Auxiliar de farmácia de manipulação",
    "Auxiliar de laboratório de análises clínicas",
    "Auxiliar de radiologia (revelação fotográfica)",
    "Auxiliar em saúde bucal da estratégia de saúde da família",
    "Auxiliar técnico em patologia clínica",
    "Biólogo",
    "Biomédico",
    "Cancerologista cirúrgico (oncologista cirúrgico)",
    "Cardiologia",
    "Cirurgia cardiovascular",
    "Cirurgia da mão",
    "Cirurgia de cabeça e pescoço",
    "Cirurgia do aparelho digestivo",
    "Cirurgia geral",
    "Cirurgia pediátrica",
    "Cirurgia plástica",
    "Cirurgia torácica",
    "Cirurgia vascular",
    "Cirurgião dentista - clínico geral",
    "Cirurgião dentista - dentística",
    "Cirurgião dentista - endodontista",
    "Cirurgião dentista - estratégia saúde da família",
    "Cirurgião dentista - ortopedista e ortodontista",
    "Cirurgião dentista - patologista bucal",
    "Cirurgião dentista - radiologista",
    "Cirurgião dentista - traumatologista bucomaxilofacial",
    "Cirurgião dentista de saúde coletiva",
    "Clínica médica",
    "Clínico geral",
    "Curso de dependência química",
    "Dermatologia",
    "Diretor de serviços de saúde",
    "Educador físico",
    "Endocrinologia e metabologia",
    "Endoscopista",
    "Enfermeiro",
    "Farmacêutico",
    "Farmacêutico bioquímico",
    "Fisiatra",
    "Fisioterapeuta esportivo",
    "Fisioterapeuta geral",
    "Fonoaudiólogo",
    "Gastroenterologia",
    "Geneticista (médico)",
    "Geriatra",
    "Ginecologia e obstetrícia",
    "Hematologia",
    "Hemoterapeuta",
    "Hiperbarista",
    "Infectologista",
    "Instrumentador cirúrgico",
    "Intensivista",
    "Legista",
    "Massagista",
    "Mastologista",
    "Medicina estética",
    "Medicina nuclear",
    "Médico coloproctologista",
    "Médico de família e comunidade",
    "Médico do trabalho",
    "Médico em medicina de tráfego",
    "Médico em medicina preventiva e social",
    "Médico estratégia saúde da família",
    "Médico homeopata",
    "Médico patologista clínico / medicina laboratorial",
    "Médico residente",
    "Médico veterinário",
    "Musicoterapeuta",
    "Nefrologia",
    "Neurocirurgião",
    "Neurofisiologia clínica",
    "Neurologia",
    "Neuropsicólogo",
    "Nutricionista",
    "Nutrologia",
    "Odontologista",
    "Oftalmologista",
    "Oncologia",
    "Ortodontia e ortopedia facial",
    "Ortopedia e traumatologia",
    "Ortoptista",
    "Otorrinolaringologista",
    "Outras",
    "Patologista",
    "Pediatria",
    "Pneumologia",
    "Podologia",
    "Preparador físico",
    "Psicologia clínica",
    "Psicopedagogo",
    "Psiquiatria",
    "Radiologista / imagenologista",
    "Radioterapeuta",
    "Reumatologia",
    "Sanitarista",
    "Terapeuta",
    "Terapeuta ocupacional",
    "Trabalhador de serviços de limpeza e conservação de áreas públicas",
    "Técnico de enfermagem",
    "Técnico de enfermagem do trabalho",
    "Técnico de laboratório de análises físico-químicas (materiais de construção)",
    "Técnico em acupuntura",
    "Técnico em fotônica",
    "Técnico em higiene dental",
    "Técnico em patologia clínica",
    "Técnico em radiologia e imagenologia",
    "Urologia",
    "Visitador sanitário",
  ];

  const formattedList = specialtiesList.map((especialidades) => {
    return { label: especialidades, value: especialidades };
  });

  const inputFields = [
    {
      name: "Nome",
    },
    {
      name: "Sobrenome",
    },
    {
      name: "Email",
      type: "Email",
    },
    {
      name: "Contato",
      mask: "(99) 99999-9999",
    },
    {
      name: "Sexo:",
      type: "radio",
      value1: "FEMININO",
      value2: "MASCULINO",
    },
    {
      name: "Data de nascimento",
      mask: "99/99/9999",
    },
    {
      name: "CPF",
      mask: "999.999.999-99",
    },
    {
      name: "CNPJ",
      mask: "99.999.999/9999-99",
    },
    {
      name: "Número de registro profissional",
    },
    {
      name: "Chave PIX",
    },
    {
      name: "Tipo chave",
      type: "select",
      placeholder: "Selecione um tipo de chave",
      list: [
        { label: "CPF", value: "CPF" },
        { label: "E-mail", value: "EMAIL" },
        { label: "Telefone", value: "TELEFONE" },
        { label: "Chave Aleatória", value: "aleatoria" },
        { label: "CNPJ", value: "CNPJ" },
      ],
    },
    {
      name: "CEP",
      mask: "99999-999",
    },
    {
      name: "Endereço",
    },
    {
      name: "Bairro",
    },
    {
      name: "Cidade",
    },
    {
      name: "Estado",
    },
    {
      name: "Número",
    },
    {
      name: "Complemento",
    },
    {
      name: "Especialidades",
      type: "multi-select",
      placeholder: "Selecione as especialidades",
      list: formattedList,
    },
    {
      name: "Senha",
      type: "password",
    },
    {
      name: "Confirmar senha",
      type: "password",
    },
  ];

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Campo obrigatório"),
    Sobrenome: Yup.string().required("Campo obrigatório"),
    Email: Yup.string().email("Email inválido").required("Campo obrigatório"),
    Contato: Yup.string().required("Campo obrigatório"),
    CPF: Yup.string().matches(/^.{14}$/, "CPF inválido")
	  	.when('CNPJ', (CNPJ, schema) => {
			if (CNPJ[0] !== undefined) {
				return CNPJ[0].length === 0 ? Yup.string().matches(/^.{14}$/, "CPF inválido").required("Informe ao menos o CPF ou CNPJ") : schema;
			}
			return Yup.string().matches(/^.{14}$/, "CPF inválido").required("Informe ao menos o CPF ou CNPJ");
	}),
	CNPJ: Yup.string().matches(/^.{18}$/, "CNPJ inválido")
		.when('CPF', (CPF, schema) => {
			if (CPF[0] !== undefined) {
				return CPF[0].length === 0 ? Yup.string().matches(/^.{18}$/, "CNPJ inválido").required("Informe ao menos o CPF ou CNPJ") : schema;
			}
			return Yup.string().matches(/^.{18}$/, "CNPJ inválido").required("Informe ao menos o CPF ou CNPJ");
	}),
    "Número de registro profissional": Yup.string()
      .required("Campo obrigatório")
      .max(15, "Máximo de 15 caracteres permitidos"),
    CEP: Yup.string()
      .matches(/^\d{8}$/, "CEP inválido")
      .required("Campo obrigatório"),
    Endereço: Yup.string().required("Campo obrigatório"),
    Bairro: Yup.string().required("Campo obrigatório"),
    Cidade: Yup.string().required("Campo obrigatório"),
    Estado: Yup.string().required("Campo obrigatório"),
    "Número": Yup.number().required("Campo obrigatório"),
    Complemento: Yup.string(),
    Especialidades: Yup.array().required("Campo obrigatório"),
    Senha: Yup.string()
      .min(8, "A senha deve ter pelo menos 8 caracteres")
      .required("Campo obrigatório"),
    "Confirmar senha": Yup.string()
      .oneOf([Yup.ref("Senha"), null], "As senhas não coincidem")
      .required("Campo obrigatório"),
    "Tipo chave": Yup.string().required("Campo obrigatório"),
    "Data de nascimento": Yup.date().required("Campo obrigatório"),
    "Sexo:": Yup.string().required("Campo obrigatório"),
    "Chave PIX": Yup.string().required("Campo obrigatório"),
    Foto: Yup.mixed().required("Adicione a foto da identidade funcional"),
	Perfil: Yup.mixed().required("Adicione uma foto de perfil funcional"),
  }, ['CNPJ', 'CPF']);

  const handleCepBlur = async (event, setFieldValue) => {
    const cep = event.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        const { data } = response;
        if (!data.erro) {
          setFieldValue("CEP", cep);
          setFieldValue("Endereço", data.logradouro);
          setFieldValue("Bairro", data.bairro);
          setFieldValue("Cidade", data.localidade);
          setFieldValue("Estado", data.uf);
        } else {
          // Limpar os outros campos se o CEP for inválido ou não for encontrado
          setFieldValue("CEP", "");
          setFieldValue("Endereço", "");
          setFieldValue("Bairro", "");
          setFieldValue("Cidade", "");
          setFieldValue("Estado", "");

          console.log("CEP inválido ou não encontrado");
        }
      } catch (error) {
        // Tratar erros de requisição
        console.log("Erro ao buscar CEP", error);
      }
    } else {
      // Limpar os outros campos caso o CEP não tenha 8 dígitos
      setFieldValue("Endereço", "");
      setFieldValue("Bairro", "");
      setFieldValue("Cidade", "");
      setFieldValue("Estado", "");
    }
  };

  const handleRadioBlur = (event, setFieldValue, selected) => {
    const value = event.target.value.replace(/\D/g, "");

    if (selected === "CPF") {
      setFieldValue("CPF", value);
      setFieldValue("CNPJ", "");
    } else {
      setFieldValue("CNPJ", value);
      setFieldValue("CPF", "");
    }
  };

  const [identityPhoto, setIdentityPhoto] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const handleFileUpload = (file, setFieldValue) => {
    setIdentityPhoto(file);
    setFieldValue("Foto", file);
  };
  const handleFileUploadProfile = (file, setFieldValue) => {
    setProfilePhoto(file);
    setFieldValue("Perfil", file);
  };

  const handleChave = (event, setFieldValue) => {
    const type = event.value;
    setFieldValue("Tipo chave", type);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "36vw",
      minHeight: "45px",
      borderRadius: "10px",
      backgroundColor: "#fafafa",
      fontSize: "larger",
      color: "black",
      border: "1px solid #e7e7e7",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black", // Cor do texto das opções
    }),
  };

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        <PageTitle>Cadastrar Prestador</PageTitle>

        <Formik
          initialValues={{
            Nome: "",
            Sobrenome: "",
            Email: "",
            Contato: "",
            CPF: "",
            CNPJ: "",
            "Número de registro profissional": "",
            "Chave PIX": "",
            "Tipo chave": "",
            CEP: "",
            Endereço: "",
            Bairro: "",
            Cidade: "",
            Estado: "",
            Especialidades: "",
            Senha: "",
            "Confirmar senha": "",
            Foto: null,
            Perfil: null,
            "Sexo:": null,
			"Data de nascimento": "",
          }}
          onSubmit={(values) => {
            console.log("values", values);
            if (!!identityPhoto && !!profilePhoto) {
              sendData(values);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            setFieldValue,
            handleBlur,
            handleChange,
            values,
            errors,
          }) => (
            <Form id="formCadastro">
              <RegisterContainer>
                {inputFields.map((field, index) => {
                  if (field.type === "select") {
                    return (
                      <>
                        <CreatableSelect
                          id={field.name}
                          name={field.name}
                          options={field.list}
                          value={field.list.filter((element) =>
                            element.value.includes(values[field.name])
                          )}
                          onChange={(e) => handleChave(e, setFieldValue)}
                          styles={customStyles}
                          placeholder={field.placeholder}
                        />
                        <ErrorMessage
                          name={field.name}
                          component={<div>erro</div>}
                        ></ErrorMessage>
                      </>
                    );
                  } else if (field.type === "multi-select") {
                    return (
                      <>
                        <CreatableSelect
                          id={field.name}
                          name={field.name}
                          options={field.list}
                          isMulti
                          value={values[field.name]}
                          onChange={(e) => {
                            setFieldValue(field.name, e);
                            console.log(values);
                          }}
                          styles={customStyles}
                          placeholder={field.placeholder}
                        />
                        <ErrorMessage
                          name={field.name}
                          component={<div>erro</div>}
                        ></ErrorMessage>
                      </>
                    );
                  } else if (field.name === "Sexo:") {
                    return (
                      <div className="flex flex-row gap-4 w-[37vw]">
                        <div
                          value={values[field.name]}
                          id={field.name}
                          name={field.name}
                        >
                          {field.name}
                        </div>
                        <div
                          className="flex  gap-4"
                          role="group"
                          aria-labelledby="my-radio-group"
                        >
                          <label>
                            <Field
                              type="radio"
                              name={field.name}
                              value={field.value1}
                              checked={values[field.name] === field.value1}
                              onChange={() => {
                                setFieldValue(field.name, field.value1);

                                console.log(values);
                                console.log(values, validationSchema);
                              }}
                            />
                            Feminino
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name={field.name}
                              value={field.value2}
                              checked={values[field.name] === field.value2}
                              onChange={() => {
                                setFieldValue(field.name, field.value2);

                                console.log(values, validationSchema);
                              }}
                            />
                            Masculino
                          </label>
                        </div>
                      </div>
                    );
                  } else if (field.name === "Data de nascimento") {
                    return (
                      <div className=" flex flex-col">
                        <p>{field.name}</p>
                        <DatePicker
                          locale="pt-BR"
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          className="w-[36vw] h-[45px] border bg-neutral-50 text-[larger] text-[black] pl-5 rounded-[10px] border-solid border-[#e7e7e7]"
                          onChange={(date) => {
                            console.log(date);
                            console.log(
                              `${date.getDate()}-${
                                date.getMonth() + 1
                              }-${date.getFullYear()}`
                            );
                            setStartDate(date);
                            setFieldValue(field.name,
                              date
                            );
                          }}
                        />
						<ErrorMessage
						name="Data de nascimento"
						component="div"
						style={{
							color: "red",
							paddingLeft: "10px",
							fontSize: "12px",
							fontWeight: "bold",
						}}
						/>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        <ColumnRow1>
							<Input
								type={field.type || "text"}
								name={field.name}
								placeholder={field.name}
								mask={field.mask}
								maskChar={null}
								value={values[field.name]}
								required
								onBlur={
								field.name === "CEP"
									? (e) => handleCepBlur(e, setFieldValue)
									: handleBlur
								}
							/>
                        </ColumnRow1>
                      </div>
                    );
                  }
                })}
                <IdentityPhotoUploader
                  text={
                    "Arraste e solte a foto da identidade funcional aqui, ou clique para selecionar um arquivo."
                  }
                  onFileUpload={(file) => {
                    handleFileUpload(file, setFieldValue);
                  }}
                />

                <ErrorMessage
                  name="Foto"
                  component="div"
                  style={{
                    color: "red",
                    paddingLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                />
                <IdentityPhotoUploader
                  text={
                    "Arraste e solte a foto de perfil aqui, ou clique para selecionar um arquivo."
                  }
                  onFileUpload={(file) => {
                    handleFileUploadProfile(file, setFieldValue);
                  }}
                />

                <ErrorMessage
                  name="Perfil"
                  component="div"
                  style={{
                    color: "red",
                    paddingLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                />
                <RegisterButton
                  type="submit"
                  form="formCadastro"
                  disabled={loading}
                >
                  {loading ? (
                    <ReactLoading
                      type="spin"
                      color="#B2EBF2"
                      height={"30px"}
                      width={"30px"}
                    />
                  ) : (
                    "Cadastrar"
                  )}
                </RegisterButton>
              </RegisterContainer>
            </Form>
          )}
        </Formik>
      </PageContainer>
    </Page>
  );
}
