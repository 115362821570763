import axios from "axios";

const refreshApi = axios.create({
    baseURL: "https://inovaclin-f1cdd023f5f9.herokuapp.com/",
  });
  
  refreshApi.interceptors.request.use(async (config) => {
    const refreshToken = localStorage.getItem("@SaudeDoPovo:refreshToken");
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    }
    return config;
  });
  
  export default refreshApi;