import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { BsArrowLeft, BsArrowRight, BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import SidebarMenu from "../../../Components/SidebarMenu";
import { ptBR } from "@mui/x-data-grid";
// import { ptBR as pickersptBR } from "@mui/x-date-pickers/locales";
import { ptBR as coreptBR } from "@mui/material/locale";
import {
  Page,
  ErrorMsg,
  ErrorBtn,
  MyDataGrid,
  Status,
} from "./styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { useAuth } from "../../../Hooks/useAuth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabela from "../../../Components/Tabela";
import StatusDisplay from "../../../Components/Status";
import PageContainer from "../../../Components/PageContainer";
import { Background, RightDiv } from "../Perfil/styles";
import DataTable from "react-data-table-component";
import ExportCSVButton from "../../../Components/ExportCSVButton";
import CreatableSelect from "react-select/creatable";

export default function Consultas() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const { user } = useAuth();
  const [infoPagina, setInfoPagina] = useState(
        {
          has_next: false,
          has_previous: false,
          per_page: 0,
          page: 1,
          total: 0,
        }
      );
    const [quantidadeExibidaNaTabela, setQuantidadeExibidaNaTabela] = useState({label: "20", value: 20});

  // const columns = [
  //   // { field: "to_user_name", headerName: "Prestador", flex: 1 },
  //   {
  //     field: "combined_name",
  //     headerName: "Paciente/Dependente",
  //     valueGetter: (params) => {
  //       const fromUserName = params.row.from_user_name;
  //       const afiliadoNome = params.row.afiliado_nome;

  //       if (fromUserName && afiliadoNome) {
  //         return `${fromUserName} / ${afiliadoNome}`;
  //       } else if (fromUserName) {
  //         return fromUserName;
  //       } else if (afiliadoNome) {
  //         return afiliadoNome;
  //       } else {
  //         return "Nome não disponível";
  //       }
  //     },
  //     flex: 1.5,
  //   },
  //   {
  //     field: "created_at",
  //     headerName: "Data",
  //     flex: 1.5,
  //     type: "dateTime",
  //     valueGetter: ({ value }) => value && new Date(value),
  //     valueFormatter: ({value}) => {
  //       // var newDate = new Date(value.getTime() - value.getTimezoneOffset()*60*1000);
  //        return new Date(value);
  //     },
  //   },
  //   { field: "amount", headerName: "Valor", flex: 0.5 },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 2,
  //     type: "singleSelect",
  //     valueOptions: ["Pending", "Successful", "Failed"],
  //     renderCell: (params) => (
  //       <StatusDisplay status={params.row.status}>
  //         {params.row.status}
  //       </StatusDisplay>
  //     ),
  //   },
  //   {
  //     field: "visualizar",
  //     type: "actions",
  //     align: "center",
  //     getActions: (params) => [
  //       <GridActionsCellItem
  //         icon={<BsEyeFill size={20} color="#7A99E3" />}
  //         onClick={() => history.push(`/consultas/${params.id}`)}
  //         label="Visualizar"
  //       />,
  //     ],
  //   },
  // ];

  const colunasTabela = [
      {
        name: 'Paciente/Dependente',
        selector: row => {
          const fromUserName = row.from_user_name;
          const afiliadoNome = row.afiliado_nome;

          if (fromUserName && afiliadoNome) {
            return `${fromUserName} / ${afiliadoNome}`;
          } else if (fromUserName) {
            return fromUserName;
          } else if (afiliadoNome) {
            return afiliadoNome;
          } else {
            return "Nome não disponível";
          }
        },
      },
      {
        name: 'Data',
        selector: row => row.create_time,
      },
      {
        name: 'Valor',
        selector: row => row.amount,
      },
      {
        name: 'Token',
        selector: row => row.token,
      },
      {
        name: 'Status',
        selector: (row) => (
          <StatusDisplay status={row.status}>
            {row.status}
          </StatusDisplay>
        ),
      },
      {
        name: '',
        maxWidth: '40px',
        selector: row => 
        <BsEyeFill
        className="cursor-pointer" 
        onClick={() => {
          history.push(`/consultas/${row.id}`);
        }} size={20} color="#7A99E3" 
        />,
      },
    ];

  const dropdownCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "10vw",
      minHeight: "30px",
      borderRadius: "10px",
      backgroundColor: "#fafafa",
      fontSize: "18px",
      color: "black",
      border: "1px solid #d5d5d5",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black", // Cor do texto das opções
    }),
  };

  const opcoesQuantidadeExibidos = [
    {label: "5", value: 5},
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20}
  ];

  const fetchAppointmentsData = async (proximaPagina = false, ultimaPagina = false, quantidadeParaExibir = quantidadeExibidaNaTabela) => {
    setLoading(true);
    setErrorRepositories(null);

    try {
      let modifier = 0;
      if (proximaPagina) {
        modifier = 1;
      }
      if (ultimaPagina) {
        modifier = -1;
      }
      const response = await api.post(`/medico/minhas_consultas?page=${infoPagina.page + modifier}&per_page=${quantidadeParaExibir.value}`, {
        procedimento: false,
      });

      const consultas = response.data.consultas.map((consulta) => {
        console.log(consulta.create_time);
        if (consulta.create_time !== null) {
          const create_time = new Date(consulta.create_time).toLocaleDateString("pt-BR");
          console.log(create_time);
          return {...consulta, create_time};
        }
        return {...consulta, create_time: 'Desconhecida'};
      });
      setAppointmentsData(consultas);
      console.log("response.data", response.data);
      setInfoPagina(
        {
          has_next: response.data.has_next,
          has_previous: response.data.has_previous,
          per_page: response.data.per_page,
          page: response.data.page,
          total: response.data.total,
        }
      );
    } catch (error) {
      setErrorRepositories("Ocorreu um erro ao carregar a página");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const proximaPagina = async (proxima) => {
    console.log("foi de next");
    if (proxima) {
      if (infoPagina.has_next) {
        fetchAppointmentsData(true, false);
      }
    }
    else {
      if (infoPagina.has_previous) {
        fetchAppointmentsData(false, true);
      }
    }
  };

  const mudarQuantidadeExibida = async(quantidade) => {
    setQuantidadeExibidaNaTabela(quantidade);
    fetchAppointmentsData(false, false, quantidade);
  }

  useEffect(() => {
    fetchAppointmentsData();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Histórico de Consultas</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainer>
        {/* <PageTitle>Histórico de Consultas</PageTitle>
        <Tabela rows={appointmentsData} columns={columns} /> */}
        <Background>
          <RightDiv>
          <PageTitle>Histórico de Consultas</PageTitle>
          <div className="flex flex-col w-full space-y-2">
              <div className="flex flex-row w-full justify-between items-center">
                <p className="text-white font-bold pr-2">Exibir:</p>
                <CreatableSelect
                  options={opcoesQuantidadeExibidos}
                  required
                  value={quantidadeExibidaNaTabela}
                  onChange={(e) => {
                    e.value > 20 ? mudarQuantidadeExibida(opcoesQuantidadeExibidos.at(-1)) : mudarQuantidadeExibida(e);
                  }}
                  styles={dropdownCustomStyles}
                  placeholder={"Selecione a quantidade que será exibida na tabela"}
                />
                <ExportCSVButton exportData={appointmentsData} />
              </div>
              <div className="w-full max-h-96 overflow-x-auto overflow-y-visible">
                <DataTable
                  columns={colunasTabela}
                  data={appointmentsData}
                  responsive
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#EFCA04",
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between items-center">
                <BsArrowLeft 
                className={infoPagina.has_previous ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(false)} 
                size={50} 
                color={infoPagina.has_previous ? "#EFCA04" : "#ebe0a7"} />
                <p className=" text-[#EFCA04]">
                  {infoPagina.page + "/" + Math.ceil(infoPagina.total/infoPagina.per_page)}
                </p>
                <BsArrowRight 
                className={infoPagina.has_next ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => proximaPagina(true)} 
                size={50} 
                color={infoPagina.has_next ? "#EFCA04" : "#ebe0a7"} />
              </div>
            </div>
          </RightDiv>
        </Background>
      </PageContainer>
    </Page>
  );
}
