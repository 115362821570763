import React from "react";
import { useHistory } from "react-router-dom";
import { SidebarMenuContainer, Logo, Menu, MenuItem, MenuLink, ItemText } from "./styles";
import SaudeLogo from "../../Assets/LogoNovi.png";
import IconLogo from "../../Assets/logo.png";
import {
	FaStethoscope,
	FaUserAlt,
	FaEdit,
	FaRegCreditCard,
	FaRegWindowClose,
	FaSignOutAlt,
	FaClipboard,
	FaPaypal,
	FaCcMastercard,
	FaBookMedical,
	FaMoneyBill,
	FaRegEdit,
	FaUserEdit,
	FaChartBar,
} from "react-icons/fa";
import { useAuth } from "../../Hooks/useAuth";

export default function SidebarMenu() {
	const history = useHistory();
	const { signOut, user } = useAuth(); // Assuming the user object has a "tipo" property

	const handleLogout = () => {
		signOut(history);
	};

	const onPageStyle = {
		color: "black",
		backgroundColor: "#EFCA04",
	};

	const SidebarData = [
		{
			title: "Cadastrar",
			path: "/cadastrar",
			icon: <FaEdit size="25px" />,
		},
		{
			title: "Usuários",
			path: "/pacientes",
			icon: <FaUserAlt size="25px" />,
		},
		{
			title: "Prestadores",
			path: "/prestadores",
			icon: <FaStethoscope size="25px" />,
		},
		{
			title: "Pendentes",
			path: "/pendentes",
			icon: <FaClipboard size="25px" />,
		},
		{
			title: "Configurações",
			path: "/creditos",
			icon: <FaUserEdit size="25px" />,
		},
		{
			title: "Gerar Pagamento",
			path: "/pagamento",
			icon: <FaCcMastercard size="25px" />,
		},

		{
			title: "Consultas",
			path: "/consultas",
			icon: <FaBookMedical size="25px" />,
		},
		{
			title: "Procedimentos",
			path: "/procedimentos",
			icon: <FaChartBar size="25px" />,
		},
		{
			title: "Perfil",
			path: "/perfil",
			icon: <FaUserAlt size="25px" />,
		},

		{
			title: "Pagamentos",
			path: "/pagamentos-administrador",
			icon: <FaMoneyBill size="25px" />,
		},

		{
			title: "Métricas",
			path: "/metricas",
			icon: <FaChartBar size="25px" />,
		},
	];

	const filteredSidebarData =
		user.vendedor
			? SidebarData.filter(
					(item) =>
						item.title === "Gerar Pagamento" ||
						item.title === "Perfil" ||
						item.title === "Consultas" ||
						item.title === "Procedimentos" ||
						item.title === "Cadastrar"
			  )
			: user.medico
			? SidebarData.filter(
					(item) =>
						item.title === "Gerar Pagamento" ||
						item.title === "Perfil" ||
						item.title === "Consultas" ||
						item.title === "Procedimentos"
			  )
			: user.admin
			? SidebarData.filter(
					(item) =>
						item.title === "Cadastrar" ||
						item.title === "Usuários" ||
						item.title === "Prestadores" ||
						item.title === "Pendentes" ||
						item.title === "Configurações" ||
						item.title === "Pagamentos" ||
						item.title === "Métricas" ||
						item.title === "Perfil"
			  )
			: [];

	return (
		<SidebarMenuContainer>
			<Logo src={SaudeLogo} />
			<Menu>
				{filteredSidebarData.map((item, index) => {
					return (
						<MenuItem key={index}>
							<MenuLink to={item.path} activeStyle={onPageStyle}>
								{item.icon}
								<ItemText> {item.title} </ItemText>
							</MenuLink>
						</MenuItem>
					);
				})}
				<MenuItem>
					<MenuLink to="/" onClick={handleLogout}>
						<FaSignOutAlt size="25px" />
						<ItemText>Logout</ItemText>
					</MenuLink>
				</MenuItem>
			</Menu>
		</SidebarMenuContainer>
	);
}
