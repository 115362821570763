import React, { createContext, useCallback, useState } from "react";
import api from "../Services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import refreshApi from "../Services/refreshApi";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [token, setToken] = useState(() => {
    const token = localStorage.getItem("@SaudeDoPovo:token");
    if (token) {
      return token;
    }
    return "";
  });

  const [refreshToken, setRefreshToken] = useState(() => {
    const token = localStorage.getItem("@SaudeDoPovo:refreshToken");
    if (token) {
      return token;
    }
    return "";
  });

  const [user, setUser] = useState(() => {
    const user = localStorage.getItem("@SaudeDoPovo:user");
    if (user) {
      return JSON.parse(user);
    }
    return {};
  });

  const signIn = useCallback(
    async (data, setLoading, setLoginError, history) => {
      setLoading(true);
      var type = "";

      try {
        const response = await api.post("/auth/login", data);
        console.log(response.data);
        const token = response.data.token;
        // const refreshToken = response.data.refresh_token;
        if (response.data.user.medico || response.data.user.admin) {
          setToken(token);
          // setRefreshToken(refreshToken);
          setUser(response.data.user);
          localStorage.setItem("@SaudeDoPovo:token", token);
          // localStorage.setItem("@SaudeDoPovo:refreshToken", refreshToken);
          localStorage.setItem("@SaudeDoPovo:user", JSON.stringify(response.data.user));
          console.log("user", response.data.user);
          type = 'admin';
          if (response.data.user.medico) {
            type = 'medico';
          }
          // type = response.data.user.tipo;
          history.push("/perfil");
          const showToastMessage = () => {
            toast.success(`Seja bem-vindo(a) ${response.data.user.NO_primeiro_nome}!`, {
              position: toast.POSITION.TOP_CENTER,
            });
          };
          showToastMessage();
        } else {
          const showToastMessage = () => {
            toast.error("Login não autorizado para pacientes.", {
              position: toast.POSITION.TOP_CENTER,
            });
          };
          showToastMessage();
          setLoginError(true);
        }
      } catch (error) {
        console.log(error);
        console.log(error.msg);
        const showToastMessage = () => {
          toast.error(`Erro ao realizar o login.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        };
        showToastMessage();
        setLoginError(true);
      }

      setLoading(false);
      return type;
    },
    []
  );

  const getNewToken = useCallback(async (history) => {
      try {
        const response = await refreshApi.get("/refresh-token");
        console.log(response.data);

        const token = response.data.token;
        const refreshToken = response.data.refresh_token;
        if (response.data.user.tipo !== "paciente") {
          setToken(token);
          setUser(response.data.user);
          localStorage.setItem("@SaudeDoPovo:token", token);
          localStorage.setItem("@SaudeDoPovo:refreshToken", refreshToken);
          localStorage.setItem("@SaudeDoPovo:user", JSON.stringify(response.data.user));
          console.log("user", response.data.user);
          const showToastMessage = () => {
            toast.success(`Seja bem-vindo(a) ${response.data.user.nome}!`, {
              position: toast.POSITION.TOP_CENTER,
            });
          };
          showToastMessage();
          history.push("/perfil");
        } else {
          const showToastMessage = () => {
            toast.error("Login não autorizado para pacientes.", {
              position: toast.POSITION.TOP_CENTER,
            });
          };
          showToastMessage();
        }
      } catch (error) {
        console.log(error);
        console.log(error.msg);
        const showToastMessage = () => {
          toast.error(`Erro ao obter o novo token.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        };
        showToastMessage();
        history.push("/login");
      }
    },
    []
  );

  const updateUserData = useCallback(
    async (data) => {
      var type = "";

      // try {
      //   if (user.tipo !== "paciente") {
      //     setUser(data);
      //     localStorage.setItem("@SaudeDoPovo:token", token);
      //     localStorage.setItem("@SaudeDoPovo:refreshtoken", refreshToken);
      //     localStorage.setItem("@SaudeDoPovo:user", JSON.stringify(response.data.user));
      //     console.log("user", response.data.user);
      //     type = response.data.user.tipo;
      //   }
      // } catch (error) {
      //   console.log(error);
      //   console.log(error.msg);
      //   const showToastMessage = () => {
      //     toast.error(`Erro ao salvar novos dados do usuário.`, {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //   };
      //   showToastMessage();
      // }
      // return type;
      console.log('usuario no context', user);
      try {
        setUser(data);
        console.log('dados usuario alterados com sucesso no context')
      } catch (error) {
        console.log(error);
        console.log(error.msg);
        const showToastMessage = () => {
          toast.error(`Erro ao salvar novos dados do usuário.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        };
        showToastMessage();
      }

    },
    []
  );

  // const getNewToken = useCallback(async () => {
  //   try {
  //     console.log('refresh token', refreshToken);
  //     const refreshTokenConfig = axios.create({
  //       baseURL: "https://inovaclin-f1cdd023f5f9.herokuapp.com/",
  //     });
      
  //     refreshTokenConfig.interceptors.request.use(async (config) => {
  //       if (refreshToken) {
  //         config.headers.Authorization = `Bearer ${refreshToken}`;
  //       }
  //       return config;
  //     });

  //     const response = await refreshTokenConfig.get("/auth/refresh-token");
  //     console.log('teste do refresh token', response.data);
  //   }
  //   catch (error) {
  //     console.log(error);
  //     console.log(error.msg);
  //     const showToastMessage = () => {
  //       toast.error(`O login expirou, faça login novamente.`, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     };
  //     showToastMessage();
  //   }
  // }, []);

  const signOut = useCallback((history) => {
    localStorage.removeItem("@SaudeDoPovo:token");
    localStorage.removeItem("@SaudeDoPovo:user");
    setToken("");
    setUser({});
    history.push("/");
  }, []);

  const isAuthenticated = () =>
    localStorage.getItem("@SaudeDoPovo:token") ? true : false;

  const isAuthorized = () => {
    console.log("user");
    console.log(user);
    const userInfo = user;
    if (userInfo.admin) {
      return 'admin';
    }
    else {
      return 'medico';
    }
    // if (typeof userInfo === "string") {
    //   return userInfo ? JSON.parse(user).tipo : "";
    // } else {
    //   return userInfo.tipo;
    // }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        getNewToken,
        updateUserData,
        token,
        refreshToken,
        user,
        setUser,
        isAuthorized,
        isAuthenticated,
      }}
    >
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  );
}
