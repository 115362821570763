import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Link, useParams } from "react-router-dom";
import api from "../../../Services/api";
import { useAuth } from "../../../Hooks/useAuth";
import MainButton from "../../../Components/MainButton";
import {
  Background,
  Content,
  Info,
  InfoBlock,
  InfoTitle,
  PageContainer,
  PageContainerDiv,
  PageTitle,
  Redefini,
  RightDiv,
} from "./styles";

function PerfilAdministrador() {
  const { user } = useAuth();
  const [dados, setDados] = useState([]);

    const fetchUserData = async () => {
      try {
        const response = await api.get(`/usuario/${user.id_usuario}`);
        console.log('vapo info perfil', response.data);
        setDados(response.data);
      } catch (error) {
        console.log(error, "Erro no perfil");
      }
    };
  
  useEffect(() => {
    fetchUserData();
  }, [user.id_usuario]);

  return (
    <PageContainerDiv>
      <SidebarMenu />
      <Background>
        <RightDiv>
          <PageTitle>Perfil</PageTitle>
          <Content>
            <InfoBlock>
              <InfoTitle>Nome:</InfoTitle>

              <Info>{dados?.NO_primeiro_nome + ' ' + dados?.NO_ultimo_nome}</Info>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Email:</InfoTitle>

              <Info>{dados?.DS_email}</Info>
            </InfoBlock>
          </Content>

          <Redefini>
            <Link to="/redefinicao-de-senha">
              <MainButton>Redefinir senha</MainButton>
            </Link>
          </Redefini>
        </RightDiv>
      </Background>
    </PageContainerDiv>
  );
}

export default PerfilAdministrador;
