import React, { useEffect, useState } from "react";
import { Page } from "../../SignedOut/Cadastro/styles";
import SidebarMenu from "../../../Components/SidebarMenu";
import { useParams } from "react-router-dom";
import api from "../../../Services/api";
import {
	InputCPF,
	InputDescription,
	SearchButton,
	InfosContainer,
	InfosText,
	LoadingText,
	PaymentButton,
	InputAndButtonContainer,
	PageTitle,
	Left,
	Right,
	TitleText,
	InputProcedimento,
	Background,
	TokenContainer,
} from "./styles";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useAuth } from "../../../Hooks/useAuth";
import PageContainer from "../../../Components/PageContainer";
import CreatableSelect from "react-select/creatable";

function Pagamento() {
	// const [isLoadingPacient, setIsLoadingPacient] = useState(false);
	// const [isLoadingDependente, setIsLoadingDependente] = useState(false);
	const [isLoadingBuscaPacienteOuAfiliado, setIsLoadingBuscaPacienteOuAfiliado] = useState(false);
	const [isLoadingProcedimento, setIsLoadingProcedimento] = useState(false);
	const [isLoadingPaymentConsulta, setIsLoadingPaymentConsulta] = useState(false);
	// const [isLoadingPaymentPacient, setIsLoadingPaymentPacient] = useState(false);
	// const [isLoadingPaymentDependente, setIsLoadingPaymentDependente] = useState(false);
	const [isLoadingPaymentProcedimento, setIsLoadingPaymentProcedimento] = useState(false);
	// const [paciente, setPaciente] = useState(null);
	// const [afiliado, setAfiliado] = useState(null);
	const [pacienteOuAfiliado, setPacienteOuAfiliado] = useState(null);
	const [valor, setValor] = useState(null);
	const [procedimento, setProcedimento] = useState(null);
	const [nomeProcedimento, setNomeProcedimento] = useState(null);
	const [cpf, setCPF] = useState(null);
	const [emailPacienteOuAfiliado, setEmailPacienteOuAfiliado] = useState(null);
	// const [cpf_paciente, setCpfPaciente] = useState(null);
	// const [cpf_afiliado, setCpfAfiliado] = useState(null);
	const [token, setToken] = useState(null);
	const [especialidades, setEspecialidades] = useState(null);

	const { user } = useAuth();

	// const handleSearchPacient = async () => {
	// 	try {
	// 		setIsLoadingPacient(true);
	// 		console.log(cpf_paciente);
	// 		const cleanCpf = cpf_paciente.replace(/\D/g, "");
	// 		console.log(cleanCpf);
	// 		const response = await api.get(`/pacientes/${cleanCpf}`);
	// 		setPaciente(response.data);
	// 		setCPF(cleanCpf);
	// 		setCpfPaciente(cleanCpf);
	// 		console.log("paciente", response.data);
	// 		setIsLoadingPacient(false);
	// 	} catch (error) {
	// 		console.log(error);
	// 		const showToastMessageError = () => {
	// 			toast.error(`Não foi possível encontrar o paciente`, {
	// 				position: toast.POSITION.TOP_CENTER,
	// 			});
	// 		};
	// 		showToastMessageError();
	// 		setIsLoadingPacient(false);
	// 		setPaciente(null);
	// 	}
	// };

	const handleSearchPacienteOuAfiliado = async () => {
		setIsLoadingBuscaPacienteOuAfiliado(true);
		try {
			console.log(emailPacienteOuAfiliado);
			const response = await api.post(`/usuario/buscar_por_email`, {email: emailPacienteOuAfiliado});
			setPacienteOuAfiliado(response.data);
			// setCPF(cleanCpf);
			// setCpfPaciente(cleanCpf);
			console.log("paciente ou afiliado", response.data);
			
		} catch (error) {
			console.log(error);
			const showToastMessageError = () => {
				toast.error(`Não foi possível encontrar o paciente ou afiliado`, {
					position: toast.POSITION.TOP_CENTER,
				});
			};
			showToastMessageError();
			setPacienteOuAfiliado(null);
		}
		setIsLoadingBuscaPacienteOuAfiliado(false);
	};

	// const handleSearchAfiliado = async () => {
	// 	try {
	// 		setIsLoadingDependente(true);
	// 		const cleanCpf = cpf_afiliado.replace(/\D/g, "");
	// 		const response = await api.get(`/afiliado/${cleanCpf}`);
	// 		setAfiliado(response.data);
	// 		console.log("afiliado", response.data);
	// 		setCpfAfiliado(cleanCpf);
	// 		setCPF(cleanCpf);
	// 		setIsLoadingDependente(false);
	// 	} catch (error) {
	// 		console.log(error);
	// 		const showToastMessageError = () => {
	// 			toast.error(`Não foi possível encontrar o afiliado`, {
	// 				position: toast.POSITION.TOP_CENTER,
	// 			});
	// 		};
	// 		showToastMessageError();
	// 		setIsLoadingDependente(false);
	// 		setAfiliado(null);
	// 	}
	// };

	// const handleSearchProcedimento = async () => {
	// 	try {
	// 		setIsLoadingProcedimento(true);
	// 		const cleanNome = nomeProcedimento.toLowerCase();
	// 		const response = await api.get(`/afiliado/${cleanNome}`);
	// 		setProcedimento(response.data);
	// 		console.log("procedimento", response.data);
	// 		setIsLoadingDependente(false);
	// 	} catch (error) {
	// 		console.log(error);
	// 		const showToastMessageError = () => {
	// 			toast.error(`Não foi possível encontrar o procedimento`, {
	// 				position: toast.POSITION.TOP_CENTER,
	// 			});
	// 		};
	// 		showToastMessageError();
	// 		setIsLoadingProcedimento(false);
	// 		setProcedimento(null);
	// 	}
	// };

	// const handleGerarPagamentoConsulta = async () => {
	// 	try {
	// 		setIsLoadingPaymentPacient(true);
	// 		console.log("paciente", pacienteOuAfiliado);
	// 		console.log("user?.medico?.id", user?.medico?.id);
	// 		console.log("paciente?.paciente?.cpf", paciente?.paciente?.cpf);
	// 		const response = await api.post(`/transferencia/gerar`, {
	// 			medico_id: user?.id,
	// 			cpf_paciente: paciente?.paciente?.cpf,
	// 			especialidade_id: user.medico.especialidades.find(
	// 				(objeto) => objeto.nome === especialidades
	// 			).id,
	// 		});
	// 		setToken(response.data.token);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setIsLoadingPaymentPacient(false);
	// 	}
	// };

	const handleGeneratePaymentConsulta = async () => {
		try {
			setIsLoadingPaymentConsulta(true);
			console.log("paciente ou afiliado", pacienteOuAfiliado);
			// console.log("paciente?.paciente?.cpf", paciente?.paciente?.cpf);
			const response = await api.post(`/transferencia/gerar-transferencia`, {
				email_cobrado: pacienteOuAfiliado.DS_email,
				especialidade_id: user.medico.especialidades.find(
					(objeto) => objeto.NO_especialidade === especialidades
				).id_especialidade,
			});
			console.log(response.data);
			setToken(response.data.CD_token);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingPaymentConsulta(false);
		}
	};

	// const handleGeneratePaymentPacient = async () => {
	// 	try {
	// 		setIsLoadingPaymentPacient(true);
	// 		console.log("paciente", paciente);
	// 		console.log("user?.medico?.id", user?.medico?.id);
	// 		console.log("paciente?.paciente?.cpf", paciente?.paciente?.cpf);
	// 		const response = await api.post(`/transferencia/gerar`, {
	// 			medico_id: user?.id,
	// 			cpf_paciente: paciente?.paciente?.cpf,
	// 			especialidade_id: user.medico.especialidades.find(
	// 				(objeto) => objeto.nome === especialidades
	// 			).id,
	// 		});
	// 		setToken(response.data.token);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setIsLoadingPaymentPacient(false);
	// 	}
	// };

	// const handleGeneratePaymentAfiliado = async () => {
	// 	try {
	// 		setIsLoadingPaymentDependente(true);
	// 		console.log("afiliado", afiliado);
	// 		console.log("user?.medico?.id", user?.medico?.id);
	// 		console.log("afiliado?.cpf_afiliado", afiliado?.cpf_afiliado);
	// 		const response = await api.post(`/transferencia/gerar`, {
	// 			medico_id: user?.id,
	// 			cpf_paciente: afiliado?.cpf_afiliado,
	// 			status: "Pendente",
	// 			especialidade_id: user.medico.especialidades.find(
	// 				(objeto) => objeto.nome === especialidades
	// 			).id,
	// 		});
	// 		setToken(response.data.token);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setIsLoadingPaymentDependente(false);
	// 	}
	// };

	const handleGeneratePaymentProcedimento = async () => {
		try {
			setIsLoadingPaymentProcedimento(true);
			// console.log("procedimento", nomeProcedimento);
			// console.log("user?.medico?.id", user?.medico?.id);
			// // console.log("afiliado?.cpf_afiliado", afiliado?.cpf_afiliado);
			// // console.log("paciente?.paciente?.cpf", paciente?.paciente?.cpf);
			// console.log(
			// 	"especialidades",
			// 	user.medico.especialidades.find((objeto) => objeto.nome === especialidades).id
			// );
			const response = await api.post(`/transferencia/gerar-transferencia-personalizada`, {
				descricao: nomeProcedimento,
				email_cobrado: pacienteOuAfiliado.DS_email,
			});
			console.log(response.data)
			setToken(response.data.CD_token);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingPaymentProcedimento(false);
		}
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: "30vw",
			minHeight: "30px",
			borderRadius: "10px",
			backgroundColor: "#fafafa",
			fontSize: "18px",
			color: "black",
			border: "1px solid #d5d5d5",
		}),
		option: (provided, state) => ({
			...provided,
			color: "black", // Cor do texto das opções
		}),
	};

	const formattedList = user.medico.especialidades ? user.medico.especialidades.map((especialidade) => {
		return { label: especialidade.NO_especialidade, value: especialidade.NO_especialidade };
	}) : [];

	console.log(formattedList);
	formattedList.unshift({label: "Nenhuma", value: null});

	return (
		<Page>
			<SidebarMenu />
			<Background>
				<PageContainer>
					<PageTitle>Gerar Pagamento</PageTitle>
					{/* <InputDescription>Selecione a especialidade</InputDescription> */}

					{especialidades ? 
					<InputDescription>Para gerar um pagamento de PROCEDIMENTO, não selecione uma especialidade</InputDescription> 
					: 
					<InputDescription>Para gerar um pagamento de CONSULTA, selecione uma especialidade</InputDescription>
					}

					<CreatableSelect
						options={formattedList}
						id="Especialidades"
						name="Especialidades"
						required
						onChange={(e) => {
							setEspecialidades(e.value);
							console.log("especialidades", especialidades);
							setEmailPacienteOuAfiliado("");
						}}
						styles={customStyles}
						placeholder={"Selecione as especialidades"}
					/>
					{/* Barra de pesquisa para o paciente */}
					{/* <InputDescription>Gere o pagamento do PACIENTE</InputDescription>
					<InputAndButtonContainer>
						<InputCPF
							placeholder="Digite o CPF do paciente"
							mask="999.999.999-99"
							maskChar={null}
							value={cpf_paciente}
							onChange={(event) => setCpfPaciente(event.target.value)}
						/>
						<SearchButton onClick={handleSearchPacient}>Pesquisar</SearchButton>
					</InputAndButtonContainer>

					{isLoadingPacient ? (
						<LoadingText>Carregando perfil do paciente...</LoadingText>
					) : paciente ? (
						<InfosContainer>
							<Left>
								<InfosText>
									Nome: <TitleText>{paciente.nome}</TitleText>
								</InfosText>
								<InfosText>
									Email:
									<TitleText>{paciente.email}</TitleText>
								</InfosText>
								<InfosText>
									CPF:
									<TitleText>{paciente.paciente.cpf}</TitleText>
								</InfosText>
								<InfosText>
									Telefone:
									<TitleText>{paciente.paciente.telefone}</TitleText>
								</InfosText>
							</Left>
							<Right>
								<InfosText>
									Valor da Consulta:
									<TitleText>{user.medico.creditos_consulta}</TitleText>
								</InfosText>
								{isLoadingPaymentPacient ? (
									<LoadingText>...</LoadingText>
								) : (
									token && (
										<InfosText>
											Token de Pagamento: <TitleText>{token}</TitleText>
										</InfosText>
									)
								)}

								<PaymentButton onClick={handleGeneratePaymentPacient}>
									Gerar Pagamento
								</PaymentButton>
							</Right>
						</InfosContainer>
					) : null}

					<InputDescription>Gere o pagamento do DEPENDENTE</InputDescription>
					<InputAndButtonContainer>
						<InputCPF
							placeholder="Digite o CPF do dependente"
							mask="999.999.999-99"
							maskChar={null}
							value={cpf_afiliado}
							onChange={(event) => setCpfAfiliado(event.target.value)}
						/>
						<SearchButton onClick={handleSearchAfiliado}>Pesquisar</SearchButton>
					</InputAndButtonContainer>

					{isLoadingDependente ? (
						<LoadingText>Carregando perfil...</LoadingText>
					) : afiliado ? (
						<InfosContainer>
							<Left>
								<InfosText>
									Nome: <TitleText>{afiliado.nome}</TitleText>
								</InfosText>
								<InfosText>
									Sexo: <TitleText>{afiliado.sexo}</TitleText>
								</InfosText>
								<InfosText>
									CPF: <TitleText>{afiliado.cpf_afiliado}</TitleText>
								</InfosText>
								<InfosText>
									Data de nascimento: <TitleText>{afiliado.data_nascimento}</TitleText>
								</InfosText>
							</Left>
							<Right>
								{isLoadingPaymentDependente ? (
									<LoadingText>...</LoadingText>
								) : (
									token && (
										<InfosText>
											Token de Pagamento: <TitleText>{token}</TitleText>
										</InfosText>
									)
								)}
								<PaymentButton onClick={handleGeneratePaymentAfiliado}>
									Gerar Pagamento
								</PaymentButton>
							</Right>
						</InfosContainer>
					) : null} */}

					{/* Caso não tenha uma especialidade e queira gerar um procedimento */}
					{!especialidades &&
					<>
					{/* <InputDescription>Para gerar um pagamento de CONSULTA, selecione uma especialidade</InputDescription> */}

					<InputDescription>Gere o pagamento do PROCEDIMENTO</InputDescription>
					<InputAndButtonContainer>
						<InputProcedimento
							placeholder="Digite o email do paciente ou afiliado"
							maskChar={null}
							value={emailPacienteOuAfiliado}
							onChange={(event) => setEmailPacienteOuAfiliado(event.target.value)}
						/>
						{/* <InputProcedimento
							className="w-[330px]"
							placeholder="Digite o nome do procedimento"
							value={nomeProcedimento}
							onChange={(event) => setNomeProcedimento(event.target.value)}
						/>
						<InputProcedimento
							className="w-[105px]"
							placeholder="Digite o valor"
							value={valor}
							onChange={(event) => setValor(event.target.value)}
						/> */}
					</InputAndButtonContainer>
					<SearchButton onClick={handleSearchPacienteOuAfiliado}>Pesquisar</SearchButton>
					{/* <div className="flex flex-col justify-center items-center gap-6">
						<SearchButton onClick={handleGeneratePaymentProcedimento}>Gerar Token</SearchButton>
					</div> */}
					{isLoadingBuscaPacienteOuAfiliado ? (
						<LoadingText>Carregando perfil do paciente...</LoadingText>
					) : pacienteOuAfiliado ? (
						<>
						<InfosContainer>
							<Left>
								<InfosText>
									Nome: <TitleText>{pacienteOuAfiliado.NO_primeiro_nome.concat(" ", pacienteOuAfiliado.NO_ultimo_nome)}</TitleText>
								</InfosText>
								<InfosText>
									Email:
									<TitleText>{pacienteOuAfiliado.DS_email}</TitleText>
								</InfosText>
								
							</Left>
							<Right>
								<InfosText>
									CPF:
									<TitleText>{pacienteOuAfiliado.NU_cpf}</TitleText>
								</InfosText>
								<InfosText>
									Telefone:
									<TitleText>{pacienteOuAfiliado.NU_telefone}</TitleText>
								</InfosText>
							</Right>
						</InfosContainer>
						<InputAndButtonContainer>
							<InputProcedimento
								className="w-[330px]"
								placeholder="Digite o nome do procedimento"
								value={nomeProcedimento}
								onChange={(event) => setNomeProcedimento(event.target.value)}
							/>
							<InputProcedimento
								className="w-[105px]"
								placeholder="Digite o valor"
								value={valor}
								onChange={(event) => setValor(event.target.value)}
							/>
						</InputAndButtonContainer>
						<div className="flex flex-col justify-center items-center gap-6">
							<SearchButton onClick={handleGeneratePaymentProcedimento}>Gerar Token</SearchButton>
						</div>
						</>
					) : null}
					{isLoadingPaymentProcedimento ? (
						<LoadingText>Gerando token...</LoadingText>
					) : (
						token && (
							<TokenContainer className="w-20%">
								<InfosText>
									Token de Pagamento: <TitleText>{token}</TitleText>
								</InfosText>
							</TokenContainer>
						)
					)}</>}

					{/* Caso tenha selecionado uma especialidade e queira gerar uma consulta */}
					{especialidades &&
					<>
					{/* <InputDescription>Para gerar um pagamento de PROCEDIMENTO, não selecione uma especialidade</InputDescription> */}

					{/* <InputDescription>Gere o pagamento do DEPENDENTE</InputDescription>
					<InputAndButtonContainer>
						<InputCPF
							placeholder="Digite o CPF do dependente"
							mask="999.999.999-99"
							maskChar={null}
							value={cpf_afiliado}
							onChange={(event) => setCpfAfiliado(event.target.value)}
						/>
						<SearchButton onClick={handleSearchAfiliado}>Pesquisar</SearchButton>
					</InputAndButtonContainer>

					{isLoadingDependente ? (
						<LoadingText>Carregando perfil...</LoadingText>
					) : afiliado ? (
						<InfosContainer>
							<Left>
								<InfosText>
									Nome: <TitleText>{afiliado.nome}</TitleText>
								</InfosText>
								<InfosText>
									Sexo: <TitleText>{afiliado.sexo}</TitleText>
								</InfosText>
								<InfosText>
									CPF: <TitleText>{afiliado.cpf_afiliado}</TitleText>
								</InfosText>
								<InfosText>
									Data de nascimento: <TitleText>{afiliado.data_nascimento}</TitleText>
								</InfosText>
							</Left>
							<Right>
								{isLoadingPaymentDependente ? (
									<LoadingText>...</LoadingText>
								) : (
									token && (
										<InfosText>
											Token de Pagamento: <TitleText>{token}</TitleText>
										</InfosText>
									)
								)}
								<PaymentButton onClick={handleGeneratePaymentAfiliado}>
									Gerar Pagamento
								</PaymentButton>
							</Right>
						</InfosContainer>
					) : null} */}
					<InputDescription>Gere o pagamento da CONSULTA</InputDescription>

					<InputAndButtonContainer>
						<InputCPF
							placeholder="Digite o email do paciente ou afiliado"
							maskChar={null}
							value={emailPacienteOuAfiliado}
							onChange={(event) => setEmailPacienteOuAfiliado(event.target.value)}
						/>
						<SearchButton onClick={handleSearchPacienteOuAfiliado}>Pesquisar</SearchButton>
					</InputAndButtonContainer>

					{isLoadingBuscaPacienteOuAfiliado ? (
						<LoadingText>Carregando perfil do paciente...</LoadingText>
					) : pacienteOuAfiliado ? (
						<InfosContainer>
							<Left>
								<InfosText>
									Nome: <TitleText>{pacienteOuAfiliado.NO_primeiro_nome.concat(" ", pacienteOuAfiliado.NO_ultimo_nome)}</TitleText>
								</InfosText>
								<InfosText>
									Email:
									<TitleText>{pacienteOuAfiliado.DS_email}</TitleText>
								</InfosText>
								<InfosText>
									CPF:
									<TitleText>{pacienteOuAfiliado.NU_cpf}</TitleText>
								</InfosText>
								<InfosText>
									Telefone:
									<TitleText>{pacienteOuAfiliado.NU_telefone}</TitleText>
								</InfosText>
							</Left>
							<Right>
								<InfosText>
									Valor da Consulta:
									<TitleText>{user.medico.VL_consulta_em_credito}</TitleText>
								</InfosText>
								{isLoadingPaymentConsulta ? (
									<LoadingText>...</LoadingText>
								) : (
									token && (
										<InfosText>
											Token de Pagamento: <TitleText>{token}</TitleText>
										</InfosText>
									)
								)}

								<PaymentButton onClick={handleGeneratePaymentConsulta}>
									Gerar Pagamento
								</PaymentButton>
							</Right>
						</InfosContainer>
					) : null}
					</>}
				</PageContainer>
			</Background>
		</Page>
	);
}

export default Pagamento;
