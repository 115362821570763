import React, { useState, useEffect } from "react";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import {
	RegisterContainer,
	RegisterInput,
	ErrorText,
	RegisterButton,
} from "../CadastrarMedico/styles";
import { PageTitle } from "../Creditos/styles";
import ReactLoading from "react-loading";
import api from "../../../Services/api";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/Input";
import { ButtonRow, LoginRow } from "../../SignedOut/Login/styles";
import PageContainer from "../../../Components/PageContainer";
import DatePicker from "react-datepicker";

export default function CadastrarVendedor() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [cpf, setCpf] = useState("");
	const [startDate, setStartDate] = useState("");
	const [invalidFields, setInvalidFields] = useState(false);
	const [passwordDiff, setPasswordDiff] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const initialValues = {
		Email: "",
		Senha: "",
		ConfirmarSenha: "",
		Nome: "",
		CPF: "",
		Sexo: "",
		Nascimento: "",
	};

	const validationSchema = Yup.object({
		Email: Yup.string().email("E-mail Inválido").required("Campo Obrigatório"),
		Senha: Yup.string()
			.min(8, "A senha deve conter pelo menos 8 caracteres")
			.required("Campo Obrigatório"),
		ConfirmarSenha: Yup.string()
			.min(8, "A senha deve conter pelo menos 8 caracteres")
			.required("Campo Obrigatório")
			.oneOf([Yup.ref("Senha")], "As senhas precisam ser iguais"),
		Nome: Yup.string().required("Campo Obrigatório"),
		CPF: Yup.string().required("Campo Obrigatório"),
		Nascimento: Yup.date().required("Campo obrigatório"),
		Sexo: Yup.string().required("Campo obrigatório"),
	});

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const data = {
				nome: "Vendedor " + values.Nome,
				email: values.Email,
				senha: values.Senha,
				sexo: values.Sexo,
				nascimento: values.Nascimento,
				tipo: "vendedor",
				vendedor: { cpf: values.CPF },
			};

			const diaNascimento = 
			values.Nascimento.getDate().length === 1
			? '0' + values.Nascimento.getDate().toString()
			: values.Nascimento.getDate().toString();
			const mesNascimento = 
			(values.Nascimento.getMonth()+1).length === 1 
			? '0' + (values.Nascimento.getMonth()+1).toString() 
			: (values.Nascimento.getMonth()+1).toString();
			const anoNascimento = values.Nascimento.getFullYear().toString();
	
			data["nascimento"] = anoNascimento+'-'+mesNascimento+'-'+diaNascimento;
			console.log(data);
			const response = await api.post("/registrar", data);
			console.log(response.data);
			const showToastMessage = () => {
				toast.success(`Cadastro realizado com sucesso!`, {
					position: toast.POSITION.TOP_CENTER,
				});
			};
			showToastMessage();
			history.push("/cadastrar");
		} catch (error) {
			console.log(error);
			const showToastMessage = () => {
				toast.error(`Erro ao cadastrar!!`, {
					position: toast.POSITION.TOP_CENTER,
				});
			};
			showToastMessage();
		}
		setLoading(false);
	};

	return (
		<Page>
			<SidebarMenu />
			<PageContainer>
				<PageTitle>Cadastrar vendedor</PageTitle>
				<RegisterContainer>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
							<Form>
								<LoginRow>
									<Input name="Nome" type="text" required value={values.Nome}></Input>
								</LoginRow>
								<LoginRow>
									<Input name="Email" type="email" required value={values.Email}></Input>
								</LoginRow>
								<LoginRow>
									<Input
										name="CPF"
										type="text"
										required
										value={values.CPF}
										onChange={handleChange}
										mask="999.999.999-99"
										maskChar={null}
									></Input>
								</LoginRow>
								<LoginRow>
									<div className="flex flex-row gap-4 w-[37vw]">
										<div
											value={values.Sexo}
											id={"Sexo"}
											name={"Sexo"}
										>
											{"Sexo"}
										</div>
										<div
											className="flex  gap-4"
											role="group"
											aria-labelledby="my-radio-group"
										>
											<label>
											<Field
												type="radio"
												name={"Sexo"}
												value={"F"}
												checked={values.Sexo === "F"}
												onChange={() => {
												setFieldValue("Sexo", "F");
				
												console.log(values);
												console.log(values, validationSchema);
												}}
											/>
											Feminino
											</label>
											<label>
											<Field
												type="radio"
												name={"Sexo"}
												value={"M"}
												checked={values.Sexo === "M"}
												onChange={() => {
												setFieldValue("Sexo", "M");
				
												console.log(values, validationSchema);
												}}
											/>
											Masculino
											</label>
										</div>
									</div>
								</LoginRow>
								<LoginRow>
									<div className=" flex flex-col">
										<p>{"Data de Nascimento"}</p>
										<DatePicker
											locale="pt-BR"
											selected={startDate}
											dateFormat="dd/MM/yyyy"
											className="w-[36vw] h-[45px] border bg-neutral-50 text-[larger] text-[black] pl-5 rounded-[10px] border-solid border-[#e7e7e7]"
											onChange={(date) => {
											console.log(date);
											console.log(
												`${date.getDate()}-${
												date.getMonth() + 1
												}-${date.getFullYear()}`
											);
											setStartDate(date);
											setFieldValue("Nascimento",
												date
											);
											}}
										/>	
										<ErrorMessage
										name="Nascimento"
										component="div"
										style={{
											color: "red",
											paddingLeft: "10px",
											fontSize: "12px",
											fontWeight: "bold",
										}}
										/>
									</div>
								</LoginRow>
								<LoginRow>
									<Input name="Senha" type="password" required value={values.Senha}></Input>
								</LoginRow>
								<LoginRow>
									<Input
										name="ConfirmarSenha"
										type="password"
										required
										value={values.ConfirmarSenha}
									></Input>
								</LoginRow>
								<ButtonRow>
									<RegisterButton disabled={isSubmitting} type="submit">
										{loading ? (
											<ReactLoading type="spin" color="#B2EBF2" height={"30px"} width={"30px"} />
										) : (
											"Cadastrar"
										)}
									</RegisterButton>
									<ErrorText>{passwordDiff ? "As senhas inseridas não coincidem" : ""}</ErrorText>
								</ButtonRow>
							</Form>
						)}
					</Formik>
				</RegisterContainer>
			</PageContainer>
		</Page>
	);
}
