import React from "react";

import { OptionBtn } from "./styles";

const MainButton = ({ children, onClick, bgcolor, disabled = false }) => {
  return (
    <OptionBtn className="flex items-center justify-center" onClick={onClick} bgcolor={bgcolor} disabled={disabled}>
      {" "}
      {children}{" "}
    </OptionBtn>
  );
};

export default MainButton;
